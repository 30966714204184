import * as React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

import {
  Layout,
  Section,
  Heading,
  NarrowContent,
  Header,
  Badge,
  BadgePlatform,
  SEO,
} from "../components";
import styled from "styled-components";

const sites = [
  {
    name: "Kaiku Demo",
    branch_url: "https://m.kaikuhealth.com/demo-kaiku",
    url: "https://kaikudemo.kaikuhealth.com",
  },
  {
    name: "Docrates",
    branch_url: "https://m.kaikuhealth.com/docrates",
    url: "https://kaiku.docrates.com",
  },
  {
    name: "Staging Mobile",
    branch_url: "https://m.kaikuhealth.com/staging-mobile",
    url: "https://staging-mobile.kaikuhealth.com",
  },
  {
    name: "Demo Roche",
    branch_url: "https://m.kaikuhealth.com/demo-roche",
    url: "https://demo-roche.kaikuhealth.com",
  },
  {
    name: "Roche Staging",
    branch_url: "https://m.kaikuhealth.com/demo-roche-staging",
    url: "https://roche-staging.kaikuhealth.com",
  },
];

const SiteList = styled.ul`
  li + li {
    margin-top: 1rem;
  }
`;

const DemoPage = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <SEO title={t("demo.title")} />
      <NarrowContent>
        <Header />
        <Section>
          <Heading>{t("demo.title")}</Heading>
          <p>{t("demo.to_use_a_demo_site")}</p>
          <ol>
            <li>
              <p>{t("demo.download_app")}</p>
              <Badge platform={BadgePlatform.Apple} />
              <Badge platform={BadgePlatform.Google} />
            </li>
            <li>{t("demo.open_page")}</li>
            <li>
              <p>{t("demo.select_site")}</p>

              <SiteList>
                {sites.map((site) => (
                  <li key={site.url}>
                    <a href={site.branch_url}>{site.name}</a> ({site.url})
                  </li>
                ))}
              </SiteList>

              <p>{t("demo.if_not_available")}</p>
            </li>
            <li>{t("demo.link_opens_application")}</li>
            <li>{t("demo.same_credentials")}</li>
          </ol>
          <p>{t("demo.change_site")}</p>
        </Section>
      </NarrowContent>
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default DemoPage;
